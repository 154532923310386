import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import api from "../../utils/api/api";
import appointmentService from "../../utils/api/v1/appointmentService";
import alliancesService from "../../utils/api/v1/alliancesService";
import nurseService from "../../utils/api/v1/nurseService";
import * as moment from "moment";
import Swal from "sweetalert2";
import { isEmpty } from "lodash";
import { useQueryParam, StringParam } from "use-query-params";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import * as regionComuna from "../../utils/comunas/region_comunas.json";
import * as estadoMunicipio from "../../utils/comunas/estado_municipio.json";
import { translateRegion } from "../../utils/comunas/regionTranslator";

// Contexts
import { FiltersContext } from "../../components/Stores/FilterStore";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import Italic from "../../components/Typography/Italic";
import LoadingError from "../../components/Loaders/LoadingError";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// interfaces
import Appointment from "../../utils/interfaces/Appointment";
import { NurseName } from "../../utils/interfaces/Nurse";
import DashboardGrid from "../../components/Appointments/DashboardGrid";

// other files
import { COUNTRIES } from "../../utils/data/constants";

const Header = styled.div`
  max-width: 1400px;
  padding: 0 3rem 2rem 3rem;
  margin: 0 auto;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

const CommuneSelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const CountrySelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const RegionSelect = styled(Select)`
  width: 250px;
  margin-bottom: 1rem;
`;

const ExamTypeSelect = styled(Select)`
  width: 175px;
`;

const PaymentStatusSelect = styled(Select)`
  width: 175px;
`;

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Appointment[];
  };
}

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const IndexPage = (props): JSX.Element => {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingNurses, setLoadingNurses] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Array<Appointment>>([]);
  const [activeNurses, setActiveNurses] = useState<NurseName[]>([]);
  const [sms, setSms] = useState<boolean>(true);
  const [fast, setFast] = useState<boolean>(false);
  // @ts-ignore
  const [filters, filtersDispatch] = useContext(FiltersContext);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest = await api.request({
        method: "get",
        url: "dashboard/appointments/receipts_list/",
        params: {
          begin_date: filters.initDate.format("YYYY-MM-DD"),
          end_date: filters.finishDate.format("YYYY-MM-DD"),
          nurse: filters.nurse,
          booked: filters.booked,
          contacted: filters.contacted,
          arrived: filters.arrived,
          visited: filters.visited,
          "receipt-sent": filters.receipt,
          "results-sent": filters.results,
          sales_source: filters.salesSource,
          country: filters.country,
          comuna: filters.comuna,
          region: translateRegion(filters.region),
          exam_type: filters.exam_type,
          is_operative: filters.is_operative,
          payment_status: filters.payment_status,
        },
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchAppointmentsBySearch = async () => {
    setLoading(true);
    try {
      const req = await appointmentService.searchAppointments(search || "");
      setAppointments(req.data.results);
      setTotalRows(req.data.count);
      setNextRequest(req.data.next.replace("http://", "https://"));
      setPrevRequest(req.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchActiveNurses = async () => {
    setLoadingNurses(true);
    try {
      const req = await nurseService.fetchActiveNurses();
      setActiveNurses(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoadingNurses(false);
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const createFilter = (
    value: string,
    actionType: string,
    tag: string
  ): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{tag}</InputLabel>
          <Select
            value={value}
            onChange={(e) => {
              filtersDispatch({
                type: actionType,
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            <MenuItem value={"yes"}>Listo</MenuItem>
            <MenuItem value={"no"}>Pendiente</MenuItem>
          </Select>
        </FormControl>
      </Spaced>
    );
  };

  const createCommuneFilter = (): JSX.Element => {
    const regiones = Object.keys(regionComuna);
    let comunas =
      regionComuna[filters?.region === "Todas" ? regiones[0] : filters?.region];
    if (filters?.country === "México") {
      comunas = estadoMunicipio[filters?.region];
    }
    const label = filters?.country === "Chile" ? "Comuna" : "Municipio";
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <CommuneSelect
            autoWidth
            value={filters?.comuna}
            disabled={filters?.region === "Todas"}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COMMUNE",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value={"Todas"}>Todas</MenuItem>
            {comunas?.map((commune: string) => (
              <MenuItem value={commune} key={commune}>
                {commune}
              </MenuItem>
            ))}
          </CommuneSelect>
        </FormControl>
      </Spaced>
    );
  };

  const createCountryFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>País</InputLabel>
          <CountrySelect
            autoWidth
            value={filters?.country}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COUNTRY",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            {COUNTRIES.map((country: string) => (
              <MenuItem value={country} key={country}>
                {country}
              </MenuItem>
            ))}
          </CountrySelect>
        </FormControl>
      </Spaced>
    );
  };

  const createRegionFilter = (): JSX.Element => {
    let regions: string[] = [];
    if (filters?.country === "Chile") {
      regions = Object.keys(regionComuna).slice(0, -1); // Remove default key
    } else if (filters?.country === "México") {
      regions = Object.keys(estadoMunicipio).slice(0, -1);
    }
    const label = filters?.country === "Chile" ? "Región" : "Estado";
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <RegionSelect
            autoWidth
            value={filters?.region}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_REGION",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value={"Todas"}>Todas</MenuItem>
            {regions.map((region: string) => (
              <MenuItem value={region} key={region}>
                {translateRegion(region)}
              </MenuItem>
            ))}
          </RegionSelect>
        </FormControl>
      </Spaced>
    );
  };

  const createSalesSourceFilter = () => {
    return filters ? (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel> Origen </InputLabel>
          <Select
            value={filters.salesSource}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_SALESOURCE",
                payload: e.target.value as string,
              });
            }}
          >
            {salesSourcesList.map((salesSource) => (
              <MenuItem value={salesSource}>{salesSource}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Spaced>
    ) : undefined;
  };

  const displayNurseFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Tomador</InputLabel>
          <Select
            disabled={loadingNurses}
            value={filters?.nurse}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_NURSE",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            {activeNurses.map((item: NurseName) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.names} {item.last_names}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Spaced>
    );
  };

  const createExamTypeFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <TextField
            fullWidth
            label="Exámenes"
            variant="standard"
            value={filters?.exam_type}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_EXAM_TYPE",
                payload: e.target.value as string,
              })}}
          />
        </FormControl>
      </Spaced>
    );
  };

  const createPaymentStautsFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Estado de pago</InputLabel>
          <PaymentStatusSelect
            autoWidth
            value={filters?.payment_status}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_PAYMENT_STATUS",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            <MenuItem value={"Payment Pending"}>Pendiente</MenuItem>
            <MenuItem value={"Invoice Sent"}>Factura Enviada</MenuItem>
            <MenuItem value={"Payment Successful"}>Pagado</MenuItem>
          </PaymentStatusSelect>
        </FormControl>
      </Spaced>
    );
  };

  const getSalesSources = async (): Promise<any> => {
    try {
      const res = await appointmentService.fetchSalesSources();
      const salesSourceList = res.data.data;
      const clientsRes = await alliancesService.fetchClients();
      const clients = clientsRes.data;
      const clientsSalesSource = clients.map((client) => client.sales_source);
      const mergedSaleSources = new Set<string>(
        salesSourceList.concat(clientsSalesSource)
      );
      const sortedMergedSaleSources = Array.from(mergedSaleSources).sort(
        (a, b) => {
          return a.localeCompare(b);
        }
      );
      setSalesSourcesList(sortedMergedSaleSources);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar la cita del dashboard de totalpack",
        allowOutsideClick: false,
      });
    }
  };

  useEffect((): void => {
    if (!search) {
      fetchAppointments();
      fetchActiveNurses();
      getSalesSources();
    }
  }, []);

  useEffect(() => {
    if (search) {
      fetchAppointmentsBySearch();
    }
  }, [search]);

  return (
    <PrivateRoute>
      <LoadingError error={error} loading={loading} />
      {!loading && isEmpty(error) && (
        <>
          <Header>
            <Flex justify="center" align="center">
              Apretar "Agendamiento Asistido"
            </Flex>
          </Header>
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
